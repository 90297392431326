import * as React from "react";
import AccordionItem from "components/common/AccordionItem";
import ModBlockSlider from "./ModBlockSlider";
import { IconMore } from "icons/customIcons";
import { Icon } from "components/common/ModIcon";
import { injectIntl } from "react-intl";
import { hasContent } from "common/Utils";
import NavLink from "components/common/NavLink";
import { FormattedMessage } from "react-intl";
import { Project, NavigationNode } from "interfaces/Interfaces";
import ModImgTag from "components/common/ModImgTag";

export interface ModBlockWorkProps {
  content: Project[];
  intl: any;
  currentLanguage: string;
}

class ModBlockWork extends React.Component<ModBlockWorkProps, {}> {
  render() {
    const { content, currentLanguage } = this.props;

    const projects = content.map((item, index) => {
      if (!item._i18nCL || !item.showInWerklist) {
        return null;
      }
      const i18n = item._i18nCL;

      const { formatMessage } = this.props.intl;

      const publicationDate = new Date(item.date).getFullYear();

      const status = formatMessage({ id: "status." + item.status });
      const proceedings = formatMessage({
        id: "proceedings." + item.proceedings,
      });
      const use = formatMessage({ id: "use." + item.use });

      const toDate = item.endDate
        ? `\u2014${new Date(item.endDate).getFullYear()}`
        : "";
      const title = [
        <>
          <span>{i18n.longTitle}</span>
          <span>{`, ${i18n.location} ${publicationDate}${toDate}`}</span>
        </>,
        status,
        proceedings,
        use,
      ];

      let navigationNode: NavigationNode;
      let page = item.page[0];
      if (page) {
        if (
          page.navigationNode &&
          typeof page.navigationNode !== "string" &&
          page.navigationNode._i18nCL
        ) {
          navigationNode = page.navigationNode;
        }
      } else {
        navigationNode = null;
      }

      let projectLink = null;
      if (hasContent(item.page) && navigationNode) {
        projectLink = (
          <NavLink
            className={"a-internal project-detail"}
            alttext={navigationNode._i18nCL.label}
            to={{
              pathname: navigationNode._i18nCL.relativeUrl,
            }}
          >
            <Icon element={IconMore} />
          </NavLink>
        );
      }

      const content = (
        <div className="accordion__content__worklist accordion__slider__content">
          <ModBlockSlider content={item.slider} index={index} />
          {projectLink}
        </div>
      );

      /* Todo: fix lazyload (set lazyLoad true) */
      const teaserImage = item.teaserImage ? (
        <ModImgTag
          imgObject={item.teaserImage}
          transformation={"c_limit"}
          fileFormat={"png"}
          disableLazyLoad={true}
        />
      ) : (
        ""
      );
      let contentMobile = null;
      if (hasContent(item.page) && navigationNode) {
        contentMobile = (
          <div className="accordion__content__worklist accordion__slider__content__mobile">
            <NavLink
              className={"a-internal project-detail"}
              alttext={navigationNode._i18nCL.label}
              to={{
                pathname: navigationNode._i18nCL.relativeUrl,
              }}
            >
              {teaserImage}
            </NavLink>
            <NavLink
              className={"a-internal project-detail-mobile"}
              alttext={navigationNode._i18nCL.label}
              to={{
                pathname: navigationNode._i18nCL.relativeUrl,
              }}
            >
              <Icon element={IconMore} />
            </NavLink>
          </div>
        );
      } else {
        contentMobile = (
          <div className="accordion__content__worklist accordion__slider__content__mobile">
            {teaserImage}
          </div>
        );
      }

      return (
        <AccordionItem
          key={item._id}
          conentType={"work"}
          active={false}
          title={title}
          classNameAcc={"worklist__item__"}
          gridConstruct={"cols-4"}
        >
          {content}
          {contentMobile}
        </AccordionItem>
      );
    });

    return <div className="">{projects}</div>;
  }
}

export default injectIntl(ModBlockWork);
