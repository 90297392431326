import * as React from 'react'
import {blockRichText} from '../../interfaces/Interfaces'
import {hasContent} from '../../common/Utils'
import ModRichText from '../common/ModRichText'
import {usePermanentVisibility} from 'utils/hooks'

export interface ModBlockRichTextProps {
  content: blockRichText
}

export default function ModBlockImageText(props: ModBlockRichTextProps) {
  const {content} = props
  let blockRef = React.createRef<HTMLDivElement>()
  const show = usePermanentVisibility(blockRef, {threshold: 0.5})

  let richTextContent = null
  if (content && content._i18nCL) {
    const i18n = content._i18nCL

    richTextContent = (
      <div className="richtext-content">
        <h2>{i18n.title}</h2>
        <ModRichText richText={i18n.text} />
      </div>
    )
  }

  return (
    <div
      className={`project__block project__detail block-grid cols-3 sections-2 unit-1 double tablet-cols-2 desktop-cols-3 ${
        show ? 'visible' : ''
      }`}
      ref={blockRef}>
      <div className="grid-unit">{richTextContent}</div>
    </div>
  )
}
