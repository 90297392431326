import * as React from 'react'
import { defaultDynamicImports } from 'common/Utils'

export interface ModIdangerousSwiperProps {
  id: string
  hasNavigation: boolean
  hasPagination: boolean
  maxPaginationCount: number
  hasSlideNumber: boolean
  children: any[]
}

export interface ModIdangerousSwiperState {
  activeSlide: number
}

export default class ModIdangerousSwiper extends React.Component<ModIdangerousSwiperProps, ModIdangerousSwiperState> {
  swiper: any

  constructor(props) {
    super(props)

    this.state = {
      activeSlide: 1
    }

    this.swiper = null
    this.destroySwiperComponents = this.destroySwiperComponents.bind(this)
    this.createSwiperComponents = this.createSwiperComponents.bind(this)
    this.onSliderSlide = this.onSliderSlide.bind(this)
  }

  componentWillUnmount() {
    this.destroySwiperComponents()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.children.length !== prevProps.children.length || !this.swiper) {
      this.createSwiperComponents().catch(console.error)
    }
  }

  componentDidMount() {
    this.createSwiperComponents().catch(console.error)
  }

  async createSwiperComponents() {
    this.destroySwiperComponents()

    const Swiper = defaultDynamicImports(await import('swiper'))

    let params = {
      slidesPerView: 'auto',
      spaceBetween: 40,
      pagination: {},
      lazy: {
        loadPrevNext: true
      },
      navigation: {},
      breakpoints: {
        599: {
          spaceBetween: 20,
          slidesPerView: 1
        }
      }
    }
    if (this.props.hasNavigation) {
      params.navigation = {
        nextEl: '#' + this.props.id + ' .swiper-button-next',
        prevEl: '#' + this.props.id + ' .swiper-button-prev'
      }
    }
    if (this.props.hasPagination) {
      params.pagination = {
        el: '#' + this.props.id + ' .swiper-pagination',
        clickable: true
      }
    }

    this.swiper = new Swiper('#' + this.props.id, params)

    this.swiper.on('slideChangeTransitionStart', () => {
      this.onSliderSlide(true)
    })
    this.swiper.on('sliderMove', () => {
      this.onSliderSlide(true)
    })
    this.swiper.on('slideChangeTransitionEnd', () => {
      this.onSliderSlide(false)
    })
  }

  onSliderSlide(activity) {
    if (this.state.activeSlide !== this.swiper.activeIndex + 1 && !activity) {
      this.setState({
        activeSlide: this.swiper.activeIndex + 1
      })
    }
  }

  destroySwiperComponents() {
    if (this.swiper) {
      this.swiper.off('onSlideChangeStart')
      this.swiper.off('onSliderMove')
      this.swiper.off('onSlideChangeEnd')
      this.swiper.destroy()
    }
  }

  addLeadingZeros(number, maxNumber) {
    let digit_count = maxNumber.toString().length
    if (digit_count < 2) digit_count = 2

    let s = number.toString()
    while (s.length < digit_count) {
      s = '0' + s
    }
    return s
  }

  render() {
    const { children, hasNavigation, hasPagination, maxPaginationCount, hasSlideNumber, id } = this.props

    let swiperButton = null

    if (hasNavigation) {
      swiperButton = (
        <>
          <div className="swiper-button-next">
            <div className="swiper-button-right"></div>
          </div>
          <div className="swiper-button-prev">
            <div className="swiper-button-left"></div>
          </div>
        </>
      )
    }

    return (
      <div className="swiper-container" id={id}>
        <div className="swiper-wrapper">{children}</div>
        {swiperButton}
        {hasPagination && children.length < maxPaginationCount ? <div className="swiper-pagination" /> : ''}
        {hasSlideNumber ? (
          <div className="swiper-slide-number">
            {this.addLeadingZeros(this.state.activeSlide, children.length)}/
            {this.addLeadingZeros(children.length, children.length)}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}
