import * as React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'
import * as isoFetch from 'isomorphic-fetch'
import ModContent from './ModContent'
import ModCookiePolicy from './ModCookiePolicy'
import { createMetaFields } from 'common/MetaFields'
import { NavigationNode, Page } from '../interfaces/Interfaces'
import ModNavigation from './ModNavigation'
import ModFooter from './ModFooter'

import { getCurrentLanguageOrFallBackByPath } from 'common/Languages'
import { addClassToElement, addCurrentLangRecursive, getJsonCookie, removeClassFromElement, setJsonCookie } from 'common/Utils'

import * as en from 'react-intl/locale-data/en'
import * as de from 'react-intl/locale-data/de'
import RootState, { LoadingState } from '../vo/RootState'
import CmsRouter, { RouterLocation, RouterLocationExtended } from '../control/CmsRouter'
import { searchNavigationNodeByUrl } from '../common/CmsUtils'
import { GoogleMapContext } from './common/GoogleMapContext'
import ModOutOfDate from './ModOutOfDate'
import { renderToString } from 'react-dom/server'

addLocaleData([...en, ...de])

export interface ModAppProps {
  location: RouterLocationExtended
  router: CmsRouter
  APP_PROPS?: RootState
}

const defaultContext: {
  location: RouterLocation
  router: CmsRouter
  rootNavigationNode: NavigationNode
} = {
  router: null,
  location: {
    pathname: '',
    query: null
  },
  rootNavigationNode: null
}

// const googleContext: {
//   googleMapKey: string
// } = {
//   googleMapKey: ''
// }

const defaultNavigation: NavigationNode = null

export const NavigationNodeContext = React.createContext(defaultContext)
// export const GoogleMapContext = React.createContext(googleContext)

export default class ModApp extends React.Component<ModAppProps, RootState> {
  constructor(props) {
    super(props)

    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)

    if (typeof window !== 'undefined') {
      // runs in frontend
      if (window.hasOwnProperty('APP_PROPS')) {
        const rootState: RootState = Object.assign(new RootState(), window['APP_PROPS'])
        addCurrentLangRecursive(rootState, currentLanguage)
        this.state = rootState
      }
    } else if (this.props.APP_PROPS) {
      // runs in backend
      this.state = this.props.APP_PROPS
      addCurrentLangRecursive(this.state, currentLanguage)
    }

    this.setStateAndCurrentLang = this.setStateAndCurrentLang.bind(this)
    this.setStateBy = this.setStateBy.bind(this)
    this.setHeadMetaInformation = this.setHeadMetaInformation.bind(this)
    this.hasUpdatedLocation = this.hasUpdatedLocation.bind(this)
    this.onCloseModalView = this.onCloseModalView.bind(this)
    this.setBodyState = this.setBodyState.bind(this)
  }

  onCloseModalView() {
    this.props.router.push({
      hash: '',
      pathname: this.props.location.pathname,
      query: null
    })
  }

  setStateAndCurrentLang(newState) {
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    newState = Object.assign(JSON.parse(JSON.stringify(this.state)), newState)
    addCurrentLangRecursive(newState, currentLanguage)
    this.setState(newState)
  }

  setHeadMetaInformation() {
    const metaFields = createMetaFields(this.state)
    document.title = metaFields.title
  }

  setStateBy(replaceObjects) {
    this.setState(Object.assign({}, this.state, replaceObjects))
  }

  hasUpdatedLocation(lastProps, nextProps) {
    try {
      if (lastProps.location.href !== nextProps.location.href) {
        return true
      }
    } catch (e) {}
    return false
  }

  isUpToDate(dateTime: string): boolean {
    return new Date().getTime() - 1000 * 60 < new Date(dateTime).getTime()
  }

  componentWillUpdate(nextProps: ModAppProps, nextState: RootState) {
    if (this.hasUpdatedLocation(this.props, nextProps)) {
      const currentLanguage = getCurrentLanguageOrFallBackByPath(nextProps.location.href)
      const nextNode = searchNavigationNodeByUrl(
        this.state.websiteSettings.rootNavigationNode,
        nextProps.location.pathname,
        currentLanguage
      )

      let isUpToDate = false
      if (nextNode && this.state.pages.hasOwnProperty(nextNode.pageId) && this.state.pages[nextNode.pageId].loadedDateTime) {
        isUpToDate = this.isUpToDate(this.state.pages[nextNode.pageId].loadedDateTime)
      }

      if (isUpToDate) {
        this.setState({
          currentPageId: nextNode.pageId
        })
        return
      } else {
        this.setState({
          loadingState: LoadingState.loading
        })
      }

      let params = new URL(nextProps.location.href).searchParams

      let fetchParams = new URLSearchParams()
      fetchParams.append('path', nextProps.location.pathname)
      const paramsString = fetchParams.toString()

      isoFetch('/api/content?' + paramsString)
        .then(function(response) {
          return response.json()
        })
        .then((page: Page) => {
          let newPage = {}
          newPage[page._id] = page
          let pages = Object.assign({}, this.state.pages, newPage)
          this.setState({
            loadingState: LoadingState.idle,
            currentPageId: page._id,
            pages
          })
        })
        .catch(error => {
          console.error(error)
          this.setState({
            loadingState: LoadingState.offline,
            currentPageId: ''
          })
        })
    }
  }

  componentDidMount() {
    this.toggleEditMode()
    this.setBodyState()

        // scroll to anchor
    setTimeout(() => {
      if(this.props.location.hash){
      const divBlock = document.getElementById(this.props.location.hash.substring(1))
      if (divBlock) {
        divBlock.scrollIntoView()
      }
    }
    }, 400)
  }

  /**
   * activates edit mode which displays direct link to the karma editor
   */
  toggleEditMode() {
    if (typeof window !== 'undefined') {
      let keysDown = {}
      let switchMode = false

      function isValidKey(key) {
        return key === 'Control' || key === 'Shift' || key === 'E'
      }

      document.addEventListener(
        'keydown',
        event => {
          if (isValidKey(event.key)) {
            keysDown[event.key] = true
            if (Object.keys(keysDown).length === 3) {
              switchMode = true
            }
          }
        },
        false
      )
      document.addEventListener(
        'keyup',
        event => {
          if (isValidKey(event.key)) {
            delete keysDown[event.key]
            if (switchMode) {
              switchMode = false
              this.setState({ editMode: !this.state.editMode })
            }
          }
        },
        false
      )
    }
  }

  setBodyState() {
    if (typeof window !== 'undefined') {
      const body = document.getElementsByTagName('BODY')[0]
      if (this.props.location.query && this.props.location.query.has('overlay')) {
        addClassToElement(body, 'overflow-hidden')
      } else {
        removeClassFromElement(body, 'overflow-hidden')
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props
    this.setHeadMetaInformation()

    if (typeof window !== 'undefined' && this.state && prevState.currentContentId !== this.state.currentPageId) {
      if (location.pageYOffset >= 0) {
        window.scrollTo(0, location.pageYOffset)
      }
    }
    this.setBodyState()

    // scroll to anchor
    setTimeout(() => {
      const divBlock = document.getElementById(this.props.location.hash.substring(1))
      if (divBlock) {
        divBlock.scrollIntoView()
      }
    }, 400)
  }

  render() {
    if (!this.state) {
      return (
        <div id="app loading">
          <p className="loading">loading</p>
        </div>
      )
    }
    const { websiteSettings } = this.state
    const { rootNavigationNode } = websiteSettings
    const currentLanguage = getCurrentLanguageOrFallBackByPath(this.props.location.pathname)
    const translations = websiteSettings.translations.translations[currentLanguage]

    return (
      <IntlProvider locale={currentLanguage} messages={translations}>
        <NavigationNodeContext.Provider
          value={{
            location: this.props.location,
            router: this.props.router,
            rootNavigationNode: rootNavigationNode
          }}
        >
          <div id="app">
            <ModNavigation
              navigationTree={rootNavigationNode}
              currentLanguage={currentLanguage}
              rootState={this.state}
              pathname={this.props.location.pathname}
            />
            <GoogleMapContext.Provider value={{ googleMapKey: websiteSettings.googleMapsApiKey }}>
              <ModContent rootState={this.state} currentLanguage={currentLanguage} />
            </GoogleMapContext.Provider>
            <ModFooter content={this.state.websiteSettings.footer} />

            {/* <ModCookiePolicy websiteSettings={this.state.websiteSettings} /> */}
          </div>
        </NavigationNodeContext.Provider>
      </IntlProvider>
    )
  }
}
