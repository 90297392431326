import * as React from 'react'

import NavLink from 'components/common/NavLink'
import { hasContent, addClassToElement, removeClassFromElement } from 'common/Utils'
import { NavigationNode } from '../interfaces/Interfaces'
import { isArticleActiveNow } from '../common/CmsUtils'
import RootState, { LoadingState } from '../vo/RootState'
import { offlineImage } from '../common/OfflineMedia'
import { supportedLanguages } from '../common/Languages'
import { Icon } from './common/ModIcon'
import { NavigationPlusSymbol, LogoGiulianiHoenger, IconClose, InstagramIcon } from 'icons/customIcons'

export interface ModNavigationState {
  menuOpen: boolean
}

export interface ModNavigationProps {
  navigationTree: NavigationNode
  rootState: RootState
  currentLanguage: string
  pathname: string
}

declare global {
  interface Window {
    lastKnownScrollPosition: number
  }
}

export default class ModNavigation extends React.Component<ModNavigationProps, ModNavigationState> {
  private lastKnownScrollPosition: number

  constructor(props) {
    super(props)

    this.state = {
      menuOpen: false
    }

    this.lastKnownScrollPosition = 0

    this.createNavigationNode = this.createNavigationNode.bind(this)
    this.removeLanguageNavigationNode = this.removeLanguageNavigationNode.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  onMouseDown(event) {
    event.preventDefault()
  }

  openMenu(event) {
    this.setState({ menuOpen: true })
  }

  closeMenu(event) {
    this.setState({ menuOpen: false })
  }

  createNavigationNode(nodes: NavigationNode[], pathname: string, currentLanguage: string) {
    const { rootState } = this.props

    function isNodeVisible(node: NavigationNode) {
      if (!node._i18nCL.showInMenu) {
        return false
      }
      if (hasContent(node.children)) {
        return true
      }
      if (node.pageId) {
        return isArticleActiveNow(rootState.pages[node.pageId], currentLanguage)
      }
      return true
    }

    if (nodes.length < 1) {
      return null
    }
    return nodes.reduce((prevItem, item, index) => {
      if (!item._i18nCL) {
        return prevItem
      }
      if (!isNodeVisible(item)) {
        return prevItem
      }

      prevItem.push(
        <div className="nav-section" key={index}>
          <hr />
          <div className="nav-section-title">
            {item._i18nCL.label}
          </div>
        </div>
      )

      if (hasContent(item.children)) {
        for (const child of item.children) {
          if (!isNodeVisible(child)) continue
          if (child._i18nCL.type.slug) {
            prevItem.push(
              <div className="nav-item" key={child._id}>
                <NavLink to={child._i18nCL.relativeUrl} alttext={child._i18nCL.label} onClick={this.closeMenu}>
                  {child._i18nCL.label}
                </NavLink>
              </div>
            )
          } else if (child._i18nCL.type.link) {
            prevItem.push(
              <div className="nav-item" key={child._id}>
                <a
                  href={child._i18nCL.relativeUrl}
                  aria-label={child._i18nCL.label}
                  target="_blank"
                  rel="noopener"
                  onClick={this.closeMenu}
                >
                  {child._i18nCL.label}
                </a>
              </div>
            )
          }
        }
      }

      return prevItem
    }, [])
  }

  createLanguageNodes(rootNode: NavigationNode, currentLanguage: string, pathname: string) {
    let languageNodes = supportedLanguages.map((lang: string) => {
      return (
        <a className={currentLanguage === lang ? 'lang active' : 'lang'} key={lang} href={`/${lang}`}>
          {lang}
        </a>
      )
    })

    return (
      <div className="navigation-meta">
        <div className="navigation-languages">{languageNodes}
          <a href="https://www.instagram.com/giulianihoenger/" target="_blank" className="instagram-icon-menu">
            <Icon element={InstagramIcon} />
          </a>
        </div>
      </div>
    )
  }

  removeLanguageNavigationNode(nodes, currentLanguage) {
    if (!(nodes instanceof Array)) {
      return null
    }
    for (let key in nodes) {
      if (nodes.hasOwnProperty(key)) {
        let node = nodes[key]
        if (node.slug === currentLanguage) {
          return node
        }
      }
    }
  }
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', function (e) {
        let scrollPositionY = window.pageYOffset || document.documentElement.scrollTop
        let app = window.document.getElementById('navigation')

        if (scrollPositionY > 40) {
          addClassToElement(app, 'hide')
        } else {
          removeClassFromElement(app, 'hide')
        }

        if (this.lastKnownScrollPosition > scrollPositionY || scrollPositionY < 44) {
          removeClassFromElement(app, 'hide')
        }

        this.lastKnownScrollPosition = scrollPositionY
      })
    }
  }

  render() {
    const { menuOpen } = this.state
    const { navigationTree, currentLanguage, pathname, rootState } = this.props
    const langNavigation = this.createLanguageNodes(navigationTree, currentLanguage, pathname)
    let mainNavigation = this.createNavigationNode(navigationTree.children, pathname, currentLanguage)

    mainNavigation.unshift(
      <button
        aria-label="close navigation"
        key="close-btn"
        id="btn-close-menu"
        type="button"
        onClick={this.closeMenu}
        onMouseDown={this.onMouseDown}
      >
        <Icon element={NavigationPlusSymbol} />
      </button>
    )

    let loadingState = null
    if (rootState.loadingState === LoadingState.loading) {
      loadingState = <img alt="loading indicator" className="loading-state" src={require('static/img/loading.gif')} />
    } else if (rootState.loadingState === LoadingState.offline) {
      loadingState = <img alt="offline icon" className="loading-state" src={offlineImage} />
    }

    return (
      <div id="navigation">
        <div className="site-header">
          {loadingState}

          <a href="/" aria-label="back to homepage" className="logo">
            <Icon element={LogoGiulianiHoenger} />
          </a>

          <button aria-label="open navigation" id="btn-menu" type="button" onClick={this.openMenu} onMouseDown={this.onMouseDown}>
            <Icon element={NavigationPlusSymbol} />
          </button>
        </div>
        <nav className={'main ' + (!menuOpen ? 'closed' : 'open')}>
          {langNavigation}
          <div className="nav-list">{mainNavigation}</div>
        </nav>
      </div>
    )
  }
}
