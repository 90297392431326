import * as React from 'react'
import { BlockMap } from 'interfaces/Interfaces'
import ModRichText from 'components/common/ModRichText'
import ModMap from 'components/common/ModMap'
import { mapStyles } from 'components/common/maps/MapStyle'
import RootState from 'vo/RootState'

export interface ModBlockMapProps {
  content: BlockMap
}

export default class ModBlockContact extends React.Component<ModBlockMapProps, {}> {
  render() {
    const { content } = this.props

    if (!content._i18nCL) {
      return null
    }

    const i18n = content._i18nCL

    return (
      <div className="contact__block block-grid cols-3 sections-2 unit-1 double">
        <div className="grid-unit">
          <ModMap
            id="googleMap"
            options={{
              center: { lat: content.lat, lng: content.long },
              zoom: 17,
              styles: mapStyles,
              mapTypeControl: false
            }}
          />
        </div>

        <div className="grid-unit">
          <h2>{i18n.title}</h2>
          <ModRichText richText={i18n.text} />
        </div>
      </div>
    )
  }
}
