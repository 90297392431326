import * as React from 'react'
import { blockImageText } from '../../interfaces/Interfaces'
import { hasContent } from '../../common/Utils'
import ModRichText from '../common/ModRichText'
import ModImgTag from '../common/ModImgTag'
import { usePermanentVisibility } from 'utils/hooks'

export interface ModBlockImageTextProps {
  projectTitle?: string
  content: blockImageText
}

export default function ModBlockImageText(props: ModBlockImageTextProps) {
  const { content, projectTitle } = props
  let blockRef = React.createRef<HTMLDivElement>()
  const show = usePermanentVisibility(blockRef, { threshold: 0.5 })

  let contentLeft = null
  if (content.columnLeft) {
    const typeLeft = content.columnLeft.type

    let className = ''
    if (typeLeft.hasOwnProperty('full')) {
      className = 'full'
    } else if (typeLeft.hasOwnProperty('centred')) {
      className = 'centred'
    }

    const caption = () => {
      if (!content.columnLeft._i18nCL) {
        return null
      }
      return <p className="caption">{content.columnLeft._i18nCL.text}</p>
    }

    contentLeft = (
      <div className={'content-left ' + className}>
        <div className="image-container">
          <ModImgTag imgObject={content.columnLeft.image} />
          {caption()}
        </div>
      </div>
    )
  }

  let contentRight = null
  if (content.columnRight) {
    const typeRight = content.columnRight.type

    if (hasContent(typeRight.text)) {
      if (typeRight.text._i18nCL) {
        const i18n = typeRight.text._i18nCL

        contentRight = (
          <div className="content-right">
            {projectTitle && <h4>{projectTitle}</h4>}
            <h2>{i18n.title}</h2>
            <ModRichText richText={i18n.text} />
          </div>
        )
      }
    } else if (hasContent(typeRight.image)) {
      const caption = () => {
        if (!content.columnRight.type.image._i18nCL) {
          return null
        }
        return <p className="caption">{content.columnRight.type.image._i18nCL.text}</p>
      }

      contentRight = (
        <div className="content-right">
          <div className="image-container">
            <ModImgTag imgObject={typeRight.image.image} />
            {caption()}
          </div>
        </div>
      )
    } else if (hasContent(typeRight.table)) {
      if (typeRight.table._i18nCL) {
        const table = typeRight.table._i18nCL.list.map((item, index) => {
          return (
            <div className="spec-list-items" key={index}>
              <div className="spec-list-item-left">
                <ModRichText richText={item.textLeft} />
              </div>
              <div className="spec-list-item-right">
                <ModRichText richText={item.textRight} />
              </div>
            </div>
          )
        })

        contentRight = (
          <div className="content-right">
            <h3>{typeRight.table._i18nCL.title}</h3>
            <div className="spec-list">{table}</div>
          </div>
        )
      }
    }
  }

  return (
    <div
      className={`project__block project__detail block-grid cols-3 sections-2 unit-1 double tablet-cols-2 desktop-cols-3 ${
        show ? 'visible' : ''
      }`}
      ref={blockRef}
    >
      <div className="grid-unit">{contentLeft}</div>
      <div className="grid-unit">{contentRight}</div>
    </div>
  )
}
