import * as React from 'react'

import ModEditBlock from '../common/ModEditBlock'
import RootState from '../../vo/RootState'
import ModBlockImageText from '../blocks/ModBlockImageText'
import ModBlockSpacer from 'components/blocks/ModBlockSpacer'
import { Project } from 'interfaces/Interfaces'
import NavLink from 'components/common/NavLink'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import VisibilitySensor from 'react-visibility-sensor'

export interface ModTemplateProjectPageProps {
  rootState: RootState
  templateData: any
  project: Project
  intl: any
}

class ModTemplateProjectPage extends React.Component<ModTemplateProjectPageProps, {}> {
  render() {
    const { templateData, rootState, project } = this.props
    const { formatMessage } = this.props.intl

    const blockList = templateData.list.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      return { typeKey, content }
    })

    let html = blockList.reduce((itemList, item, index) => {
      const { typeKey, content } = item

      let blockMarkup = null
      switch (typeKey) {
        case 'blockImageText':
          if (index == 0) {
            const fromDate = `${new Date(project.date).getFullYear()}`
            const toDate = project.endDate ? `\u2014${new Date(project.endDate).getFullYear()}` : ''
            const proceedings = formatMessage({ id: 'proceedings.' + project.proceedings })

            const projectTitle = `${project._i18nCL.longTitle}, ${project._i18nCL.location}, ${fromDate}${toDate}, ${proceedings}`
            blockMarkup = <ModBlockImageText key={index} content={content.block} projectTitle={projectTitle} />
          } else {
            blockMarkup = <ModBlockImageText key={index} content={content.block} />
          }
          break
        case 'blockSpacer':
          blockMarkup = <ModBlockSpacer key={index} content={content.block} />
          break
      }

      if (rootState.editMode && blockMarkup) {
        itemList.push(<ModEditBlock key={'_' + index} entityObject={content.block} title={typeKey} rootState={rootState} />)
      }
      itemList.push(blockMarkup)
      return itemList
    }, [])

    let overviewLinkPath = null
    let linkText = null
    switch (project.status) {
      case 'realized':
        if (rootState.websiteSettings.realizedNavigationNode._i18nCL) {
          overviewLinkPath = rootState.websiteSettings.realizedNavigationNode._i18nCL.relativeUrl
          linkText = formatMessage({ id: 'projectDetail.realized' })
        }
        break
      case 'running':
        if (rootState.websiteSettings.runningNavigationNode._i18nCL) {
          overviewLinkPath = rootState.websiteSettings.runningNavigationNode._i18nCL.relativeUrl
          linkText = formatMessage({ id: 'projectDetail.ongoing' })
        }
        break
      case 'closed':
        if (rootState.websiteSettings.closedNavigationNode._i18nCL) {
          overviewLinkPath = rootState.websiteSettings.closedNavigationNode._i18nCL.relativeUrl
          linkText = formatMessage({ id: 'projectDetail.ongoing' })
        }
        break
      case 'planning':
        if (rootState.websiteSettings.plansNavigationNode._i18nCL) {
          overviewLinkPath = rootState.websiteSettings.plansNavigationNode._i18nCL.relativeUrl
          linkText = formatMessage({ id: 'projectDetail.ongoing' })
        }
        break
      case 'competitions':
        if (rootState.websiteSettings.contestsNavigationNode._i18nCL) {
          overviewLinkPath = rootState.websiteSettings.contestsNavigationNode._i18nCL.relativeUrl
          linkText = formatMessage({ id: 'projectDetail.contests' })
        }
        break
    }

    return (
      <VisibilitySensor minTopValue={0} partialVisibility={true} delayedCall={true}>
        {({ isVisible }) => (
          <div className={isVisible ? 'template-block-list visible' : 'template-block-list loading'}>
            {html}

            <NavLink
              className={'a-internal back2overview'}
              alttext={overviewLinkPath}
              to={{
                pathname: overviewLinkPath,
              }}
            >
              <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 40 40">
                <polygon points="39.7,37.2 22.6,20 39.7,2.8 36.9,0 16.9,20 36.9,40 " />
              </svg>
              {/*) <FormattedMessage id="projectDetail.overview" defaultMessage="Overview" />
          {linkText}*/}
            </NavLink>
          </div>
        )}
      </VisibilitySensor>
    )
  }
}

export default injectIntl(ModTemplateProjectPage)
