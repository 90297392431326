import { hasContent } from "./Utils";
import RootState from "../vo/RootState";

export function createMetaFields(rootState: RootState) {
  const fields = {
    siteName: '',
    title: '',
    description: '',
    keywords: '',
    shareImage_url: '',
    shareImage_secureUrl: '',
    shareImage_format: '',
    shareImage_width: 0,
    shareImage_height: 0,
  }

  const { websiteSettings } = rootState

  if (websiteSettings) {
    fields.siteName = websiteSettings._i18nCL.siteName
    fields.title = websiteSettings._i18nCL.title
    fields.description = websiteSettings._i18nCL.description

    if (websiteSettings.shareImage) {
      fields.shareImage_url = websiteSettings.shareImage.media.backend.cloudinary.url
      fields.shareImage_secureUrl = websiteSettings.shareImage.media.backend.cloudinary.secure_url
      fields.shareImage_format = 'image/' + websiteSettings.shareImage.media.backend.cloudinary.format
      fields.shareImage_width = websiteSettings.shareImage.media.backend.cloudinary.width
      fields.shareImage_height = websiteSettings.shareImage.media.backend.cloudinary.height
    }
  }

  if (rootState.pages && rootState.pages.hasOwnProperty(rootState.currentPageId)) {
    const page = rootState.pages[rootState.currentPageId]

    if (page._i18nCL) {
      if (hasContent(page._i18nCL.title)) {
        fields.title += ' | ' + page._i18nCL.title
      }
      if (hasContent(page._i18nCL.description)) {
        fields.description = page._i18nCL.description
      }
    }

    if (page.shareImage) {
      try {
        fields.shareImage_url = page.shareImage.media.backend.cloudinary.url
        fields.shareImage_secureUrl = page.shareImage.media.backend.cloudinary.secure_url
        fields.shareImage_format = 'image/' + page.shareImage.media.backend.cloudinary.format
        fields.shareImage_width = page.shareImage.media.backend.cloudinary.width
        fields.shareImage_height = page.shareImage.media.backend.cloudinary.height
      } catch (e) {
      }
    }
  }

  return fields
}