import * as React from "react";
import { BlockNews, News } from "../../interfaces/Interfaces";
import AccordionItem from "components/common/AccordionItem";
import ModRichText from "components/common/ModRichText";
import ModAnchorTag from "components/common/ModAnchorTag";
import ModImgTag from "components/common/ModImgTag";
import { getImageWidht } from "common/Utils";

export interface ModBlockNewsProps {
  content: BlockNews;
}

export default class ModBlockNews extends React.Component<
  ModBlockNewsProps,
  {}
> {
  render() {
    const { content } = this.props;

    const news = content.news.map((item, index) => {
      if (!item._i18nCL) {
        return null;
      }
      const i18n = item._i18nCL;

      const date = new Date(item.date);
      const publicationDate =
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

      const title = [
        <span className="news__date">{publicationDate}</span>,
        <a id={item.anchor}>
          <span className="news__title desktop__title">{i18n.title}</span>
        </a>,
      ];

      return (
        <AccordionItem
          key={item._id}
          conentType={"news"}
          active={index <= 2 ? true : false}
          title={title}
          classNameAcc={"news__header__"}
          gridConstruct={"cols-3"}
        >
          <NewsContent newsItem={item} index={index} />
        </AccordionItem>
      );
    });

    return <div className="news_accordion">{news}</div>;
  }
}

interface NewsContentProps {
  newsItem: News;
  index: number;
  onChange?: () => {};
}

function NewsContent({ newsItem: item, index, onChange }: NewsContentProps) {
  const i18n = item._i18nCL;

  let secondImage = null;

  const secondImageWidth = item.image.media.backend.cloudinary.width;
  const secondImageHeight = item.image.media.backend.cloudinary.height;
  const secondNewHeight = 300;
  const secondAspectRatio = secondImageWidth / secondImageHeight;
  const secondNewWidth = secondNewHeight * secondAspectRatio;

  if (item.secondImage) {
    secondImage = (
      <ModImgTag
        imgObject={item.secondImage}
        transformation={"c_limit"}
        fileFormat={"png"}
        height={secondNewHeight}
        width={secondNewWidth}
      />
    );
  }

  const [newHeight, setNewHeight] = React.useState(600);
  const imageWidth = item.image.media.backend.cloudinary.width;
  const imageHeight = item.image.media.backend.cloudinary.height;
  const newWidth = getImageWidht(imageWidth, imageHeight, newHeight);

  React.useEffect(() => {
    if (window && window.innerWidth >= 1500) {
      setNewHeight(1000);
    } else {
      setNewHeight(600);
    }

    const updateWindowDimensions = () => {
      if (window && window.innerWidth >= 1500) {
        setNewHeight(1000);
      } else {
        setNewHeight(600);
      }
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className="accordion__content__news block-grid cols-3 sections-2 unit-1 double">
      <div className="news_item__1 grid-unit">
        <ModAnchorTag key={index} linkObject={i18n.link}>
          <ModImgTag
            onLoaded={onChange}
            imgObject={item.image}
            transformation={"c_limit"}
            fileFormat={"png"}
            height={newHeight}
            width={newWidth}
          />
          {secondImage}
        </ModAnchorTag>
      </div>
      <div className="news__item__3 grid-unit">
        <h3 className="mobile__title">{i18n.title}</h3>
        <ModRichText richText={i18n.text} />
      </div>
    </div>
  );
}
