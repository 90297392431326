import * as React from 'react'

import { Footer } from '../interfaces/Interfaces'
import ModRichText from './common/ModRichText'

export interface ModFooterProps {
  content: Footer
}

export default class ModFooter extends React.Component<ModFooterProps, {}> {
  render() {
    const { content } = this.props

    if (!content) {
      return null
    }

    return (
      <footer id="footer">
        <h5>{content._i18nCL.title}</h5>
        <div className="footer_content_left"><ModRichText richText={content._i18nCL.blockLeft} /></div>
        <div className="footer_content_right"><ModRichText richText={content._i18nCL.blockCenter} /></div>
        <div className="footer_content_right claim">
          <ModRichText richText={content._i18nCL.blockRight} />
        </div>
      </footer>
    )
  }
}
