import * as React from "react";
import { Icon } from "components/common/ModIcon";
import {
  IconArrowUp,
  IconArrowDown,
  IconClose,
  IconLine,
} from "icons/customIcons";
import ModBlockPublications from "components/blocks/ModBlockPublications";
import ModBlockWork from "components/blocks/ModBlockWork";
import { injectIntl } from "react-intl";

interface ModBlockPublicationsProps {
  content: any;
  key: number;
  type: string;
  header: {
    key: string;
    title: any;
    isStaticText: boolean;
    isTimeStamp: boolean;
    isActiveSort: boolean;
  }[];
  classNameBase: string;
  listCols: string;
  intl: any;
  currentLanguage: string;
}

function AccordionSortHeader(props: ModBlockPublicationsProps) {
  const [asc, setAsc] = React.useState(false);
  const [filterKey, setFilterKey] = React.useState<string | null>(null);
  const [activeDefaultSort, setActiveDefault] = React.useState(true);

  const sortetContent = React.useMemo(
    () =>
      filterKey ? props.content.slice().sort(compareValues) : props.content,
    [props.content, asc, filterKey]
  );

  function compareValues(a, b) {
    const leaf = (obj, path) =>
      path.split(".").reduce((value, el) => value[el], obj);

    let aItem = leaf(a, filterKey);
    let bItem = leaf(b, filterKey);

    const currentObject = props.header.find((o) => o.key === filterKey);

    if (currentObject.hasOwnProperty("isStaticText")) {
      if (currentObject.isStaticText) {
        const { formatMessage } = props.intl;
        aItem = formatMessage({ id: filterKey + "." + aItem });
        bItem = formatMessage({ id: filterKey + "." + bItem });
      }
    }

    const varA = typeof aItem === "string" ? aItem.toUpperCase() : aItem;
    const varB = typeof bItem === "string" ? bItem.toUpperCase() : bItem;

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    if (currentObject.isTimeStamp) {
      return asc == false ? comparison * -1 : comparison;
    }

    return asc == true ? comparison * -1 : comparison;
  }

  function getArrow(item) {
    if (filterKey === item.key) {
      if (!asc) {
        return <Icon element={IconArrowUp} />;
      }
      return <Icon element={IconArrowDown} />;
    }

    if (item.isActiveSort && activeDefaultSort) {
      return <Icon element={IconArrowUp} />;
    }

    return <Icon element={IconLine} />;
  }

  const headerContent = props.header.map((item, index) => {
    return (
      <div
        className={
          props.classNameBase + "__item__" + (index + 1) + " grid-unit"
        }
        onClick={() => {
          setAsc(
            item.key === filterKey || (item.isActiveSort && activeDefaultSort)
              ? !asc
              : false
          );
          setFilterKey(item.key);
          setActiveDefault(false);
        }}
        key={index}
      >
        {item.title}
        {getArrow(item)}
      </div>
    );
  });

  let content = null;
  switch (props.type) {
    case "blockPublications":
      content = <ModBlockPublications content={sortetContent} />;
      break;
    case "blockWork":
      content = (
        <ModBlockWork
          content={sortetContent}
          currentLanguage={props.currentLanguage}
        />
      );
      break;
  }

  return (
    <div className={"accordion__" + props.classNameBase}>
      <div
        className={
          "sorted__header accordion__content__" +
          props.classNameBase +
          " block-grid " +
          props.listCols
        }
      >
        {headerContent}
      </div>
      {content}
    </div>
  );
}

export default injectIntl(AccordionSortHeader);
