import * as React from 'react'
import RootState from 'vo/RootState'
import ModRichText from './common/ModRichText'
import { Icon404 } from 'icons/customIcons'
import { Icon } from 'components/common/ModIcon'

export interface Mod404Props {
  rootState: RootState
}

export default class Mod404 extends React.Component<Mod404Props, {}> {
  render() {
    const { rootState } = this.props

    return (
      <div className="page404">
        <a href="/" aria-label="back to homepage" className="backtohome">
          <ModRichText richText={rootState.websiteSettings._i18nCL.error404Text} />
          <Icon element={Icon404} />
        </a>
      </div>
    )
  }
}
