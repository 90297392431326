import * as React from 'react'

export interface AccordionItemProps {
  active?: boolean
  height?: any
  title: any // ReactNode[] | string[]
  classNameAcc: string
  gridConstruct: string
  children: React.ReactNode
  conentType?: string
}

export function AccordionItem(props: AccordionItemProps) {
  let [active, setActive] = React.useState(props.active || false)
  let [height, setHeight] = React.useState('0px')
  const [noAnimation, setNoAnition] = React.useState(false)
  const [noHoverEffect, setNoHoverEffect] = React.useState(false)
  const [width, setWidth] = React.useState(typeof window !== 'undefined' ? window.innerWidth : null)

  React.useEffect(() => {
    const handleResize = () => setWidth(typeof window !== 'undefined' ? window.innerWidth : null)
    const height = props.height ? props.height : innerContent.current.scrollHeight

    if (innerContent.current.scrollHeight === 0) {
      setNoHoverEffect(true)
    }

    setHeight(active ? `${height}px` : '0px')

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  })

  const content = React.useRef(null)
  const innerContent = React.useRef(null)

  React.useEffect(() => {
    if (props.active) {
      const height = props.height ? props.height : content.current.scrollHeight
      setHeight(`${height}px`)
      setNoAnition(true)
    }
  }, [content])

  function toggleAccordion() {
    setActive(!active)
    const height = props.height ? props.height : content.current.scrollHeight

    setHeight(active ? '0px' : `${height}px`)
    setNoAnition(false)
  }

  const title = props.title.map((item, index) => {
    return (
      <div key={index} className={'accordion__title__' + props.classNameAcc + (index + 1) + ' grid-unit'}>
        <h4>{item}</h4>
      </div>
    )
  })

  return (
    <div className={'accordion__section ' + props.conentType}>
      <button
        aria-label="open accordion"
        className={`accordion__header ${active ? 'active' : ''} ${noHoverEffect ? 'noHoverEffect' : ''} `}
        onClick={toggleAccordion}
      >
        <div className={' accordion__title__container block-grid ' + props.gridConstruct}>{title}</div>
      </button>
      <div ref={content} style={{ height: `${height}` }} className={`accordion__content ${noAnimation ? 'noAnimation' : ''}`}>
        <div ref={innerContent} className="accordion__text">
          {/* hack to trigger new height calculation on accordion item child changed */}
          {React.Children.map(props.children, (childNode) => {
            if (React.isValidElement(childNode)) {
              return React.cloneElement(childNode, {
                onChange: () => {
                  const height = props.height ? props.height : content.current.scrollHeight
                  setHeight(active ? '0px' : `${height}px`)
                }
              })
            } else {
              return childNode
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default AccordionItem
