import * as React from 'react'
import ModEditBlock from '../common/ModEditBlock'
import RootState from '../../vo/RootState'
import ModBlockGrid from 'components/blocks/ModBlockGrid'
import ModBlockHomeGrid from 'components/blocks/ModBlockHomeGrid'
import VisibilitySensor from 'react-visibility-sensor'

export interface ModTemplateHomeProps {
  rootState: RootState
  templateData: any[]
  currentLanguage: string
}

export default class ModTemplateHome extends React.Component<ModTemplateHomeProps, {}> {
  render() {
    const { templateData, rootState, currentLanguage } = this.props

    const blockList = templateData.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      return { typeKey, content }
    })

    let html = blockList.reduce((itemList, item, index) => {
      const { typeKey, content } = item

      let blockMarkup = null
      switch (typeKey) {
        case 'projectOverview':
          blockMarkup = <ModBlockGrid key={index} content={content} />
          break
        case 'home':
          blockMarkup = <ModBlockHomeGrid key={index} content={content} rootState={rootState} currentLanguage={currentLanguage} />
          break
      }

      if (rootState.editMode && blockMarkup) {
        itemList.push(<ModEditBlock key={'_' + index} entityObject={content} title={typeKey} rootState={rootState} />)
      }
      itemList.push(blockMarkup)
      return itemList
    }, [])

    return (
      <VisibilitySensor minTopValue={0} partialVisibility={true} delayedCall={true}>
          {({ isVisible }) => (
             <div className={isVisible ? 'template-block-list visible': 'template-block-list loading'}>{html}</div>
         )}
      </VisibilitySensor>
    )
  }
}
