import * as React from 'react'
import { IconChrome, IconFirefox, IconTypewriter, IconClose } from 'icons/customIcons'
import { Icon } from 'components/common/ModIcon'

export interface ModOutOfDateProps {
  currentLanguage: string
}

export default class ModOutOfDate extends React.Component<ModOutOfDateProps, {}> {
  render() {
    const translations = {
      en: {
        title: 'Your browser is not up to date anymore.',
        update: 'Please update to the latest version or change your browser.',
        browser: 'The installation of a modern browser is recommended for the following reasons:',
        technologies: 'New features and advanced technologies can be used that results in a better browsing experience for you.',
        old: 'Outdated browsers can have',
        issues: 'security',
        show: 'issues.',
        advice: 'We recommend'
      },
      de: {
        title: 'Ihr Browser ist nicht mehr aktuell.',
        update: 'Bitte aktualisieren Sie auf die neuste Version oder wechseln Sie den Browser.',
        browser: 'Die Installation eines modernen Browsers ist aus folgenden Gründen empfehlenswert:',
        technologies:
          'Es können neue Funktionen und vortschrittliche Technologien genutzt werden, dass ergibt ein besseres Browsererlebnis für Sie.',
        old: 'Veraltete Browser können',
        issues: 'Sicherheitslücken',
        show: 'aufweisen.',
        advice: 'Wir empfehlen'
      }
    }

    const { currentLanguage } = this.props

    const i18n = translations[currentLanguage]

    return (
      <div className="outOfDate-wrapper">
        <div className="outOfDate-content">
          <a id="buorgig" role="button">
            <Icon element={IconClose} />
          </a>

          <div className="info-wrapper">
            <div className="icon-typewriter-wrapper">
              <Icon element={IconTypewriter} />
            </div>
            <h1>
              <strong>{i18n.title}</strong>
            </h1>
            <br />
            <br />
            <p>{i18n.update}</p>
            <br />
            <br />
            <br />
            <p>{i18n.browser}</p>
            <br />
            <br />
            <ul>
              <li>{i18n.technologies}</li>
              <br />
              <li>
                {i18n.old} <strong>{i18n.issues}</strong> {i18n.show}
              </li>
            </ul>
          </div>

          <div className="download-wrapper">
            <h2>
              <strong>{i18n.advice}</strong>
            </h2>
            <div className="download-icon-wrapper">
              <a href="https://www.mozilla.org/firefox/new" target="_blank">
                <Icon element={IconFirefox} />
                Firefox
              </a>
              <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">
                <Icon element={IconChrome} />
                Chrome
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
