import {
  SerializedMedia as KarmaMedia,
  CloudinaryResponse,
  Image,
} from '@karma.run/editor-media-common'

export type CloudinaryMedia = KarmaMedia<{cloudinary: CloudinaryResponse}>

export interface IStateController {
  publicConfig: IPublicSettings
  editMode: boolean
  cookies: {
    cookie_consent_dismissed: boolean
  }
  domain: Domain
  websiteSettings: Website
  pages: {[key: string]: Page}
  currentPageId: string
}

export interface Website {
  internalDescription: string
  _i18nCL: {
    title: string
    siteName: string
    description: string
    cookiePolicy: string
    error404Text: RichText
  }
  googleAnalyticsProperty: string
  googleTagManagerKey: string
  googleMapsApiKey: string
  footer: Footer
  newsNavigationNode: NavigationNode
  rootNavigationNode?: NavigationNode
  realizedNavigationNode: NavigationNode
  runningNavigationNode: NavigationNode
  contestsNavigationNode: NavigationNode
  closedNavigationNode: NavigationNode
  plansNavigationNode: NavigationNode
  translations: Translations
  shareImage?: Media
}

export interface Translations {
  internalDescription: string
  projectId: string
  translations: {
    [key: string]: {
      [key: string]: string
    }
  }
}

export interface RichText {
  blocks: {
    data: any
    depth: number
    entityRanges: any[]
    inlineStyleRanges: any[]
    key: string
    text: string
    type: string
  }[]
  entityMap: any
}

export interface Link {
  document?: Media
  internalLink?: InternalLink
  url?: string
}

export interface BlockWork {
  _id: string
  internalDescription: string
  projects: Project[]
}

export interface Project {
  _id: string
  internalDescription: string
  _i18nCL: {
    location: string
    title: string
    link: Link
    longTitle: string
  }
  prio: Boolean
  status: string
  proceedings: string
  use: string
  date: string
  endDate?: string
  teaserImage: Media
  slider: Media[]
  page: Page[]
  showInWerklist: boolean
}

export interface BlockNews {
  _id: string
  internalDescription: string
  news: News[]
}

export interface News {
  _id: string
  internalDescription: string
  image: Media
  secondImage: Media
  anchor?: string
  date: string
  _i18nCL: {
    title: string
    text: RichText
    link: Link
  }
}

export interface BlockMap {
  _id: string
  internalDescription: string
  lat: number
  long: number
  _i18nCL: {
    title: string
    text: RichText
  }
}

export enum TeamPosition {
  'foundingPartner' = 'foundingPartner',
  'partner' = 'partner',
  'projectManagement' = 'projectManagement',
  'architectM' = 'architectM',
  'architectF' = 'architectF',
  'learnerM' = 'learnerM',
  'learnerF' = 'learnerF',
  'traineeF' = 'traineeF',
  'traineeM' = 'traineeM',
  'technicalDraughtsmanF' = 'technicalDraughtsmanF',
  'technicalDraughtsmanM' = 'technicalDraughtsmanM',
  'administration' = 'administration',
  'graphic' = 'graphic',
}
export interface BlockTeam {
  _id: string
  internalDescription: string
  imageLeft: Media
  imageRight: Media
  people: {
    _id: string
    internalDescription: string
    _i18nCL: {
      email: string
      jobTitle: string
      name: string
      text: RichText
    }
    position: TeamPosition
    phone: string
  }[]
}

export interface blockRichText {
  _id: string
  internalDescription: string
  _i18nCL: {
    preTitle?: string
    title: string
    text: RichText
  }
}

export interface blockImageText {
  _id: string
  internalDescription: string
  columnLeft: {
    image: Media
    _i18nCL: {
      text: string
    }
    type: {
      full: any
      centred: any
    }
  }
  columnRight: {
    type: {
      text: {
        _i18nCL: {
          preTitle?: string
          title: string
          text: RichText
        }
      }
      image: {
        image: Media
        _i18nCL: {
          text: string
        }
      }
      table: {
        _i18nCL: {
          title: string
          list: {
            textLeft: RichText
            textRight: RichText
          }[]
        }
      }
    }
  }
}

export interface BlockTestimonial {
  _id: string
  internalDescription: string
  _i18nCL: {
    text: RichText
  }
  image: Media
}

export interface BlockJobs {
  _id: string
  internalDescription: string
  _i18nCL: {
    title: string
    list: {
      title: string
      text: RichText
    }[]
  }
  image: Media
}

export interface BlockGrid {
  _id: string
  internalDescription: string
  projects: Project[]
}

export interface BlockHomeGrid {
  news: News[]
  running: Project[]
  competitions: Project[]
  realized: Project[]
  planning: Project[]
}

export interface BlockPublications {
  _id: string
  internalDescription: string
  publications: Publication[]
}

export enum PublicationType {
  Exhibition = 'exhibition',
  Print = 'print',
  Award = 'award',
  TextAboutGH = 'textAbout',
  TextFromGH = 'textFrom',
  Publication = 'publication',
}

export interface PublicationPrintOrExhibition {
  _i18nCL: {
    title: string
    text: RichText
    link?: Link
    publication: string
  }
  image: Media
}

export interface PublicationPublicationOrAward {
  _i18nCL: {
    title: string
    //awardsOrSources: { name: string }[]
    awardsOrSources: any
    more?: {
      awardsOrSources: any
    }
  }
}
export interface Publication {
  _id: string
  internalDescription: string
  release: string // release date wird einfach für die Sortierung gebraucht
  printOrExhibition?: PublicationPrintOrExhibition
  publicationOrAward?: PublicationPublicationOrAward
  publicationType: PublicationType
  // TODO remove
  // _i18nCL: {
  //   title: string
  //   text: RichText
  //   link: Link
  //   publication: string
  // },
  project?: Project
}

export interface BlockSlider {
  image: Media
}

export interface BlockSpacer {
  _id: string
  internalDescription: string
  value: number
}

export interface InternalLink {
  anchor: any
  node: NavigationNode | string
}

export interface Footer {
  internalDescription: string
  _i18nCL: {
    title: string
    blockCenter: RichText
    blockLeft: RichText
    blockRight: RichText
  }
}

export interface Media {
  _id: string
  _type: string
  internalDescription: string
  media: CloudinaryMedia
  _i18nCL: {
    title: string
    altText: string
    media?: CloudinaryMedia
  }
}

export interface NavigationNode {
  _id: string
  internalDescription?: string
  i18n: {
    [key: string]: {
      label: string
      showInMenu: boolean
      type: {
        slug: string
        link: string
      }
      relativeUrl: string
    }
  }
  _i18nCL: {
    label: string
    showInMenu: boolean
    type: {
      slug: string
      link: string
    }
    relativeUrl: string
  }
  children: NavigationNode[]
  pageId: string
}

export interface Page {
  _id: string
  _type: string
  internalDescription: string
  _i18nCL: {
    title: string
    description: string
    isLive: boolean
  }
  publishDate?: string
  expiryDate?: string
  navigationNode?: string | NavigationNode
  shareImage?: Media
  loadedDateTime: string
  content: {
    templateBlockList: any[]
    templateProjectPage: {
      list: any
      project: Project
    }
    templateHome: any[]
  }
}

export interface IPublicSettings {
  cloudinaryCloudName: string
  mediaProxy: string
  caasEditorUrl: string
}

export interface Domain {
  internalDescription: string
  domains: string[]
  forwards: {
    pattern: string
    regex: boolean
    target: {
      navigationNode: {
        node: string
      }
      url: string
    }
  }[]
  serve?: Website | string
}

declare global {
  interface Window {
    google: typeof google
  }

  interface Document {
    documentMode?: any
  }
}
