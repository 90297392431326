import * as React from 'react'

import { Link, NavigationNode } from '../../interfaces/Interfaces'
import NavLink from './NavLink'
import { NavigationNodeContext } from '../ModApp'
import { getCurrentLanguageOrFallBackByPath } from '../../common/Languages'
import { findNavigationNodeById } from '../../common/CmsUtils'

export interface ModAnchorTagProps {
  linkObject: Link
  hasIcon?: boolean
  className?: string
}

export default class ModAnchorTag extends React.Component<ModAnchorTagProps, {}> {
  static invalidateChildren(children) {
    if (typeof children === 'string' || children instanceof Array) {
      return <>{children}</>
    } else if (typeof children === 'undefined') {
      return null
    }
    return children
  }

  render() {
    let children = ModAnchorTag.invalidateChildren(this.props.children)

    if (!(typeof this.props.linkObject === 'object')) {
      return children
    }

    const { linkObject, hasIcon } = this.props

    let className = ''
    if (this.props.className) {
      className = this.props.className
    }

    try {
      if (linkObject.hasOwnProperty('url')) {
        if (linkObject.url === '') {
          return children
        }
        if (linkObject.url.startsWith('mailto:')) {
          return (
            <a className={'a-mail ' + className} href={linkObject.url}>
              {children}
            </a>
          )
        }
        return (
          <a className={'a-external ' + className} href={linkObject.url} target="_blank" rel="noopener">
            {children}
          </a>
        )
      } else if (linkObject.hasOwnProperty('internalLink')) {
        const internalLink = linkObject.internalLink

        return (
          <NavigationNodeContext.Consumer>
            {({ location, rootNavigationNode }) => {
              let navigationNode: NavigationNode
              if (typeof internalLink.node === 'string') {
                navigationNode = findNavigationNodeById(internalLink.node, rootNavigationNode)
              } else {
                navigationNode = findNavigationNodeById(internalLink.node._id, rootNavigationNode)
              }

              if (!navigationNode) {
                console.warn(`navigationNode ${internalLink.node} not found`)
                return children
              }

              const currentLanguage = getCurrentLanguageOrFallBackByPath(location.pathname)
              if (internalLink.anchor && internalLink.anchor.blockTitle) {
                const blockTitle = internalLink.anchor.blockTitle
                if (blockTitle.i18n && blockTitle.i18n.hasOwnProperty(currentLanguage)) {
                  const anchor = blockTitle.i18n[currentLanguage].anchor

                  return (
                    <NavLink
                      className={'a-internal ' + className}
                      alttext={navigationNode._i18nCL.label}
                      to={{
                        pathname: navigationNode._i18nCL.relativeUrl,
                        hash: '#' + anchor.toLowerCase()
                      }}
                    >
                      {children}
                    </NavLink>
                  )
                }
              }
              return (
                <NavLink
                  className={'a-internal ' + className}
                  alttext={navigationNode._i18nCL.label}
                  to={{
                    pathname: navigationNode._i18nCL.relativeUrl
                  }}
                >
                  {children}
                </NavLink>
              )
            }}
          </NavigationNodeContext.Consumer>
        )
      } else if (linkObject.hasOwnProperty('document') && typeof this.props.linkObject.document === 'object') {
        let mediaUrl = linkObject.document.media.url
        if (linkObject.document._i18nCL && linkObject.document._i18nCL.media) {
          // use i18 media if present
          mediaUrl = linkObject.document._i18nCL.media.url
        }
        return (
          <a className={'a-document ' + className} href={mediaUrl} target="_blank" rel="noopener">
            {children}
          </a>
        )
      }
    } catch (e) {
      console.warn(e)
    }

    return children
  }
}
