import {Domain, IPublicSettings, IStateController, Page, Website} from "../interfaces/Interfaces"

export enum LoadingState {
  idle = 'idle',
  loading = 'loading',
  offline = 'offline',
  reload = 'reload'
}

export default class implements IStateController {
  pages: { [key: string]: Page }
  currentPageId: string
  websiteSettings: Website
  domain: Domain
  cookies: { cookie_consent_dismissed: boolean }
  editMode: boolean
  publicConfig: IPublicSettings
  loadingState: LoadingState

  constructor() {
    this.pages = {}
    this.websiteSettings = null
    this.publicConfig = null
    this.domain = null
    this.cookies = {
      cookie_consent_dismissed: false
    }
    this.editMode = false
    this.loadingState = LoadingState.idle
  }
}
