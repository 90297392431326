import * as React from 'react'
import ModImgTag from '../common/ModImgTag'
import NavLink from 'components/common/NavLink'
import { BlockGrid, NavigationNode } from 'interfaces/Interfaces'
import { reduceArrayIntoThree } from '../../common/Utils'
import VisibilitySensor from 'react-visibility-sensor'

export interface ModBlockGridProps {
  content: BlockGrid
}

export default function ModBlockGrid(props: ModBlockGridProps){
    const { content } = props
    let blockRef = React.createRef<HTMLDivElement>()

    const list = content.projects.map((item, index) => {
      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL

      let navigationNode: NavigationNode
      let page = item.page[0]
      if (page) {
        if (typeof page.navigationNode !== 'string') {
          navigationNode = page.navigationNode
        }
      }

      if (!navigationNode || !navigationNode._i18nCL) {
        return null
      }

      return (
        <VisibilitySensor key={item._id} minTopValue={0} partialVisibility={true} delayedCall={true}>
          {({ isVisible }) => (
          <div key={index} className={isVisible ? 'project__block animate visible' : 'project__block animate'} >
           <NavLink
             key={'news_' + index}
             className={'a-internal'}
             alttext={navigationNode._i18nCL.label}
             to={{
               pathname: navigationNode._i18nCL.relativeUrl
             }}
           >
             <div className="img-container">
               <ModImgTag imgObject={item.teaserImage} />
             </div>
             <h4 className="caption">{i18n.title}</h4>
           </NavLink>
         </div>
         )}
        </VisibilitySensor>
      )
    })

    const array = reduceArrayIntoThree(list)
    const items = array.map((item, index) => {
      return (
        <div className="grid-unit" key={index}>
          {item}
        </div>
      )
    })

    return <div className="home__grid block-grid cols-3 tablet-cols-1 desktop-cols-3">{items}</div>
  }
