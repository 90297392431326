import * as React from 'react'
import { BlockJobs } from '../../interfaces/Interfaces'
import ModImgTag from '../common/ModImgTag'
import ModRichText from '../common/ModRichText'
import { draftJsHasContent } from '../../common/Utils'

export interface ModBlockJobsProps {
  content: BlockJobs
}

export default class ModBlockJobs extends React.Component<ModBlockJobsProps, {}> {
  render() {
    const { content } = this.props

    if (!content._i18nCL) {
      return null
    }

    const i18n = content._i18nCL

    const list = i18n.list.map((item, index) => {
      return (
        <div key={index} className="grid-unit jobs__content">
            <h2>{i18n.title}</h2>
            <h3>{item.title}</h3>
            <ModRichText richText={item.text} />
        </div>
      )
    })

    return (
      <div className="block-jobs block-grid cols-3 sections-2 unit-1 double">
        <ModImgTag className="grid-unit" imgObject={content.image} />
        {list}
      </div>
    )
  }
}
