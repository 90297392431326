import * as React from 'react'
import ModAnchorTag from '../common/ModAnchorTag'
import ModImgTag from '../common/ModImgTag'
import ModRichText from '../common/ModRichText'
import AccordionItem from 'components/common/AccordionItem'
import { injectIntl } from 'react-intl'
import { Publication, PublicationType, PublicationPrintOrExhibition, PublicationPublicationOrAward } from 'interfaces/Interfaces'
import { Icon } from 'components/common/ModIcon'
import { IconArrowRight, IconMore, IconChevronDown, IconChevronUp } from 'icons/customIcons'

interface ModBlockPublicationsProps {
  content: Publication[]
  intl: any
  onChange: () => {}
}

class ModBlockPublications extends React.Component<ModBlockPublicationsProps, {}> {
  render() {
    const { content, onChange } = this.props
    const { formatMessage } = this.props.intl
    let exhibitionContent = []
    let printContent = []
    let awardContent = []
    let textFromContent = []
    let textAboutContent = []
    let publicationContent = []

    content.sort((a, b) => {
      return new Date(b.release).getTime() - new Date(a.release).getTime()
    })

    content.map((item, index) => {
      if (
        !(
          (item.publicationOrAward && item.publicationOrAward._i18nCL) ||
          (item.printOrExhibition && item.printOrExhibition._i18nCL)
        )
      ) {
        return null
      }
      const type = item.publicationType

      switch (type) {
        case PublicationType.Exhibition:
          // exhibitionContent.push(createPrintOrExhibitionItem(item.printOrExhibition, item._id))
          exhibitionContent.push(createExhibitionItem(item.printOrExhibition, item._id))
          break

        case PublicationType.Print:
          printContent.push(createPrintItem(item.printOrExhibition, item._id))
          break

        case PublicationType.Award:
          awardContent.push(<PublicationOrAwardItem item={item.publicationOrAward} id={item._id}></PublicationOrAwardItem>)
          break

        case PublicationType.TextFromGH:
          textFromContent.push(<PublicationOrAwardItem item={item.publicationOrAward} id={item._id}></PublicationOrAwardItem>)
          break

        case PublicationType.TextAboutGH:
          textAboutContent.push(<PublicationOrAwardItem item={item.publicationOrAward} id={item._id}></PublicationOrAwardItem>)
          break

        case PublicationType.Publication:
          publicationContent.push(<PublicationOrAwardItem item={item.publicationOrAward} id={item._id}></PublicationOrAwardItem>)
          break

        default:
          break
      }
    })

    return (
      <div className="accordion__publication">
        <div className="accordion__container">
          <AccordionItem
            key={'exhibition'}
            conentType={'exhibition'}
            active={false}
            title={[formatMessage({ id: 'publicity.exhibitions' })]}
            classNameAcc={'publication__item__'}
            gridConstruct={'cols-2'}
          >
            {exhibitionContent}
          </AccordionItem>

          <AccordionItem
            key={'print'}
            conentType={'print'}
            active={false}
            title={[formatMessage({ id: 'publicity.print' })]}
            classNameAcc={'publication__item__'}
            gridConstruct={'cols-2'}
          >
            {printContent}
          </AccordionItem>

          <AccordionItem
            key={'award'}
            conentType={'award'}
            active={false}
            title={[formatMessage({ id: 'publicity.awards' })]}
            classNameAcc={'publication__item__'}
            gridConstruct={'cols-2'}
          >
            {awardContent}
          </AccordionItem>

          <AccordionItem
            key={'textAboutGH'}
            conentType={'textAboutGH'}
            active={false}
            title={[formatMessage({ id: 'publicity.textsAboutGH' })]}
            classNameAcc={'publication__item__'}
            gridConstruct={'cols-3'}
          >
            {textAboutContent}
          </AccordionItem>

          <AccordionItem
            key={'textFromGH'}
            conentType={'textFromGH'}
            active={false}
            title={[formatMessage({ id: 'publicity.textsFromGH' })]}
            classNameAcc={'publication__item__'}
            gridConstruct={'cols-3'}
          >
            {textFromContent}
          </AccordionItem>

          <AccordionItem
            key={'publication'}
            conentType={'publication'}
            active={false}
            title={[formatMessage({ id: 'publicity.publications' })]}
            classNameAcc={'publication__item__'}
            gridConstruct={'cols-2'}
          >
            {publicationContent}
          </AccordionItem>
        </div>
      </div>
    )
  }
}
export default injectIntl(ModBlockPublications)

function createExhibitionItem(item: PublicationPrintOrExhibition, id: string) {
  const { _i18nCL: i18n, image } = item

  return (
    <div key={id} className="accordion__content__publication block-grid cols-3">
      <div className="publication__item__1 grid-unit">
        <div className="rich-text accordion__title">
          <h3>{i18n.title}</h3>
          <p>{i18n.publication}</p>
        </div>
      </div>
      {/* <div className="publication__item__2 grid-unit">
        <ModAnchorTag linkObject={i18n.link}>
          <ModImgTag imgObject={image} transformation={'c_limit'} fileFormat={'png'} height={300} />
        </ModAnchorTag>
      </div> */}
      <div className="publication__item__2 grid-unit">
        <ModRichText richText={i18n.text} />
      </div>
    </div>
  )
}

function createPrintItem(item: PublicationPrintOrExhibition, id: string) {
  const { _i18nCL: i18n, image } = item

  return (
    <div key={id} className="accordion__content__publication block-grid cols-3">
      <div className="publication__item__1 grid-unit">
        <div className="rich-text accordion__title">
          <h3>{i18n.title}</h3>
          <p>{i18n.publication}</p>
        </div>
        <ModRichText richText={i18n.text} />
      </div>
      <div className="publication__item__2 grid-unit">
        <ModAnchorTag linkObject={i18n.link}>
          <ModImgTag imgObject={image} transformation={'c_limit'} fileFormat={'png'} height={300} />
        </ModAnchorTag>
      </div>
      {/* <div className="publication__item__3 grid-unit">
        <ModRichText richText={i18n.text} />
      </div> */}
    </div>
  )
}

interface PublicationOrAwardItemProps {
  item: PublicationPublicationOrAward
  id: string
  onChange?: () => {}
}

function PublicationOrAwardItem({ item, id, onChange }: PublicationOrAwardItemProps) {
  if (!item._i18nCL.awardsOrSources) {
    return null
  }

  const awardsOrSourcesContent = item._i18nCL.awardsOrSources.map((item) => {
    return <li>{item.name}</li>
  })

  let moreAwardsOrSourcesContent = null
  if (item._i18nCL.more) {
    moreAwardsOrSourcesContent = item._i18nCL.more.awardsOrSources.map((item) => {
      return <li>{item.name}</li>
    })
  }

  return (
    <div key={id} className="accordion__content__publication block-grid cols-3">
      <div className="publication__item__1 grid-unit">
        <div className="rich-text accordion__title">
          <h3>{item._i18nCL.title}</h3>
        </div>
      </div>
      <div className="publication__item__2 grid-unit">
        <div className="rich-text">
          <ul>{awardsOrSourcesContent}</ul>
        </div>
        {item._i18nCL.more && (
          <MoreSources onChange={onChange}>
            <div className="rich-text">
              <ul>{moreAwardsOrSourcesContent}</ul>
            </div>
          </MoreSources>
        )}
      </div>
    </div>
  )
}

interface MoreSourcesProps {
  children: React.ReactNode
  onChange: () => {}
}
function MoreSources({ children, onChange }: MoreSourcesProps) {
  const [isOpen, setOpen] = React.useState(false)

  function onMoreClicked(open: boolean) {
    onChange()
    setOpen(open)
  }

  return (
    <>
      {isOpen ? (
        <>
          <button
            className="publication_more_btn"
            aria-label="close more"
            key="close-more-btn"
            id="btn-close-more"
            type="button"
            onClick={(e) => onMoreClicked(false)}
          >
            <Icon element={IconChevronUp} />
          </button>
          {children}
        </>
      ) : (
        <button
          className="publication_more_btn"
          aria-label="open more"
          key="open-more-btn"
          id="btn-open-more"
          type="button"
          onClick={(e) => onMoreClicked(true)}
        >
          <Icon element={IconChevronDown} />
        </button>
      )}
    </>
  )
}
