import * as React from 'react'
import ModImgTag from 'components/common/ModImgTag'
import ModPhotoSwipe from 'components/common/ModPhotoSwipe'
import ModIdangerousSwiper from 'components/common/ModIdangerousSwiper'
import ModLazyLoad from 'components/common/ModLazyLoad'
import { ModLazyLoadState } from '../common/ModLazyLoad'
import ModLazyLoadView from '../common/ModLazyLoadView'
import { RefObject } from 'react'
import { hasContent, getImageWidht } from 'common/Utils'
import { Media } from 'interfaces/Interfaces'

export interface ModBlockSliderProps {
  content: Media[]
  index: number
}

interface ModBlockSliderState extends ModLazyLoadState {
  fullScreenSliderIndex: number
}

export default class ModBlockSlider extends ModLazyLoad<ModBlockSliderProps, ModBlockSliderState> {
  myRef: RefObject<ModIdangerousSwiper>

  constructor(props) {
    super(props)

    this.myRef = React.createRef()

    this.state = {
      fullScreenSliderIndex: -1,
      visible: true
    }
    this.onFullScreen = this.onFullScreen.bind(this)
    this.didCloseFullScreenSlider = this.didCloseFullScreenSlider.bind(this)
  }

  onFullScreen(sliderIndex) {
    this.setState({
      fullScreenSliderIndex: sliderIndex
    })
  }

  didCloseFullScreenSlider() {
    this.setState({
      fullScreenSliderIndex: -1
    })
  }

  render() {
    const { content, index } = this.props

    if (!hasContent(content)) {
      return null
    }

    let inlineSlider = null
    if (this.state.visible) {
      inlineSlider = content.map((item, index) => {
        const imageWidth = item.media.backend.cloudinary.width
        const imageHeight = item.media.backend.cloudinary.height
        const newHeight = 150
        const newWidth = getImageWidht(imageWidth, imageHeight, newHeight)

        return (
          <div className="swiper-slide" key={index} style={{ width: newWidth, height: newHeight }}>
            <div className="block-slider-img-container" onClick={() => this.onFullScreen(index)}>
              <ModImgTag imgObject={item} swiperLazy={false} height={newHeight} width={newWidth} />
              {/* <div className="swiper-lazy-preloader" /> */}
            </div>
          </div>
        )
      })

      inlineSlider = (
        <ModIdangerousSwiper
          id={'block_slider_' + index}
          ref={this.myRef}
          hasNavigation={true}
          hasPagination={false}
          maxPaginationCount={10}
          hasSlideNumber={false}
        >
          {inlineSlider}
        </ModIdangerousSwiper>
      )
    } else {
      inlineSlider = (
        <div className="aspect-ratio">
          <ModLazyLoadView
            rRef={input => {
              this.lazyComponent = input
            }}
          />
        </div>
      )
    }

    const fullScreenSlider =
      this.state.fullScreenSliderIndex >= 0 ? (
        <ModPhotoSwipe
          index={index}
          sliderIndex={this.state.fullScreenSliderIndex}
          content={content}
          onClose={this.didCloseFullScreenSlider}
        />
      ) : null

    return (
      <div className="block-slider stylized-focus">
        {inlineSlider}
        {fullScreenSlider}
      </div>
    )
  }
}
