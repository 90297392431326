import * as React from 'react'

import ModBlockSpacer from '../blocks/ModBlockSpacer'
import ModEditBlock from '../common/ModEditBlock'
import RootState from '../../vo/RootState'
import ModBlockJobs from '../blocks/ModBlockJobs'
import ModBlockTeam from '../blocks/ModBlockTeam'
import ModBlockImageText from '../blocks/ModBlockImageText'
import ModBlockNews from '../blocks/ModBlockNews'
import ModBlockContact from '../blocks/ModBlockContact'
import AccordionSortHeader from 'components/common/AccordionSortHeader'
import {FormattedMessage} from 'react-intl'
import ModBlockPublications from 'components/blocks/ModBlockPublications'
import VisibilitySensor from 'react-visibility-sensor'
import ModBlockRichText from 'components/blocks/ModBlockRichText'

export interface ModTemplateBlockListProps {
  rootState: RootState
  templateData: any[]
  currentLanguage: string
}

export default class ModTemplateBlockList extends React.Component<ModTemplateBlockListProps, {}> {
  render() {
    const {templateData, rootState, currentLanguage} = this.props

    const blockList = templateData.map((_item, index) => {
      const typeKey = Object.keys(_item)[0]
      const content = _item[typeKey]
      return {typeKey, content}
    })

    let html = blockList.reduce((itemList, item, index) => {
      const {typeKey, content} = item

      let blockMarkup = null
      switch (typeKey) {
        case 'blockJobs':
          blockMarkup = <ModBlockJobs key={index} content={content.block} />
          break
        case 'blockMap':
          blockMarkup = <ModBlockContact key={index} content={content.block} />
          break
        case 'blockTeam':
          blockMarkup = <ModBlockTeam key={index} content={content.block} />
          break
        case 'blockImageText':
          blockMarkup = <ModBlockImageText key={index} content={content.block} />
          break
        case 'blockRichText':
          blockMarkup = <ModBlockRichText key={index} content={content} />
          break
        case 'blockPublications':
          // const publicationClassName = 'publication'
          // const publicationListCols = 'cols-3'
          // const publicationHeader = [
          //   {
          //     key: '_i18nCL.title',
          //     title: <FormattedMessage id="publications.project" defaultMessage="Project" />
          //   },
          //   {
          //     key: '_i18nCL.publication',
          //     title: <FormattedMessage id="publications.publication" defaultMessage="Publication" />
          //   },
          //   {
          //     key: 'release',
          //     title: <FormattedMessage id="publications.edition" defaultMessage="Edition" />,
          //     isTimeStamp: true,
          //     isActiveSort: true
          //   }
          // ]

          blockMarkup = (
            <ModBlockPublications content={content.block.publications} />
            // <AccordionSortHeader
            //   key={index}
            //   content={content.block.publications}
            //   type={typeKey}
            //   header={publicationHeader}
            //   classNameBase={publicationClassName}
            //   listCols={publicationListCols}
            // />
          )
          break

        case 'blockNews':
          blockMarkup = <ModBlockNews key={index} content={content.block} />
          break
        case 'blockWork':
          const workListClassName = 'worklist'
          const workListCols = 'cols-4'
          const workListHeader = [
            {
              key: 'date',
              title: <FormattedMessage id="worklist.year" defaultMessage="Year" />,
              isTimeStamp: true,
              isActiveSort: true,
            },
            {
              key: 'status',
              title: <FormattedMessage id="worklist.status" defaultMessage="Status" />,
              isStaticText: true,
              isActiveSort: true,
            },
            {
              key: 'proceedings',
              title: <FormattedMessage id="worklist.proceedings" defaultMessage="Proceedings" />,
              isStaticText: true,
              isActiveSort: true,
            },
            {
              key: 'use',
              title: <FormattedMessage id="worklist.use" defaultMessage="Use" />,
              isStaticText: true,
              isActiveSort: true,
            },
          ]

          blockMarkup = (
            <AccordionSortHeader
              key={index}
              content={content.block.projects}
              type={typeKey}
              header={workListHeader}
              classNameBase={workListClassName}
              listCols={workListCols}
              currentLanguage={currentLanguage}
            />
          )
          break
        case 'blockSpacer':
          blockMarkup = <ModBlockSpacer key={index} content={content.block} />
          break
        default:
          blockMarkup = null
      }

      if (rootState.editMode && blockMarkup) {
        itemList.push(
          <ModEditBlock
            key={'_' + index}
            entityObject={content.block}
            title={typeKey}
            rootState={rootState}
          />
        )
      }
      itemList.push(blockMarkup)
      return itemList
    }, [])

    return (
      <VisibilitySensor minTopValue={0} partialVisibility={true} delayedCall={true}>
        {({isVisible}) => (
          <div
            className={isVisible ? 'template-block-list visible' : 'template-block-list loading'}>
            {html}
          </div>
        )}
      </VisibilitySensor>
    )
  }
}
