import 'babel-polyfill'
import * as React from 'react'
import { hydrate } from 'react-dom'
import App from './App'
import { renderToString } from 'react-dom/server'
import ModOutOfDate from 'components/ModOutOfDate'
import { getCurrentLanguageOrFallBackByPath } from 'common/Languages'

require('intersection-observer')

declare var DEVELOPMENT: string
if (typeof DEVELOPMENT !== 'undefined' && DEVELOPMENT === 'development') {
  require('../static/scss/index_external.scss')
}

const browserUpdate = require('browser-update')
const currentLanguage = getCurrentLanguageOrFallBackByPath(window.location.pathname)

browserUpdate({
  required: { e: -2, f: 66, o: -3, s: 11, c: 74 },
  api: 2020.02,
  insecure: true,
  text: renderToString(<ModOutOfDate currentLanguage={currentLanguage} />),
  reminder: 24,
  reminderClosed: 150
  // test: true
})

hydrate(<App />, document.getElementById('app-wrapper'))
