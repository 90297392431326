import * as React from 'react'

import { BlockTeam } from '../../interfaces/Interfaces'
import ModRichText from '../common/ModRichText'
import ModImgTag from 'components/common/ModImgTag'
import AccordionItem from '../common/AccordionItem'
import { injectIntl } from 'react-intl'
import { richTextHasContent } from 'common/Utils'

export interface ModBlockTeamProps {
  content: BlockTeam
  intl: any
}

class ModBlockTeam extends React.Component<ModBlockTeamProps, {}> {
  render() {
    const { content } = this.props

    var ordering = {},
      sortOrder = [
        'foundingPartner',
        'partner',
        'projectManagement',
        'architect',
        'technicalDraughtsman',
        'learner',
        'trainee',
        'administration',
        'graphic'
      ]
    // for (var i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i
    function getOrderPos(teamPosition: string) {
      for (var i = 0; i < sortOrder.length; i++) {
        if (teamPosition.includes(sortOrder[i])) {
          return i
        }
      }
      console.warn('Team position not included in ordering', teamPosition)
      return -1
    }

    const orderedTeam = content.people.sort(function (a, b) {
      const aOrderPos = getOrderPos(a.position)
      const bOrderPos = getOrderPos(b.position)
      if (aOrderPos == bOrderPos && a._i18nCL && b._i18nCL) {
        return a._i18nCL.name.localeCompare(b._i18nCL.name)
      }
      return aOrderPos - bOrderPos
    })

    const people = orderedTeam.map(item => {
      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL

      const { formatMessage } = this.props.intl
      const position = formatMessage({ id: 'team.' + item.position })
      const cv = richTextHasContent(i18n.text) ? 'CV' : ''

      const title = [i18n.name, position, cv]

      const content = (
        <div className="accordion__content__team block-grid cols-3">
          <div className="team__item__1 grid-unit">
            <div className="text">
              <p>{i18n.jobTitle}</p>
              <p>
                <a className={'a-mail'} target="blank" href={'mailto:' + i18n.email}>
                  {i18n.email}
                </a>
              </p>
              {item.phone && <p> {item.phone} </p>}
            </div>
          </div>
          <div className="team__item__2 grid-unit"></div>
          <div className="team__item__3 grid-unit">
            <ModRichText richText={i18n.text} />
          </div>
        </div>
      )

      return (
        <AccordionItem key={item._id} conentType={'team'} title={title} classNameAcc={'team__item__'} gridConstruct={'cols-3'}>
          {content}
        </AccordionItem>
      )
    })

    return (
      <>
        <div className="block-grid cols-3 sections-2 unit-1 double team__content">
          <div className="grid-unit">
            <ModImgTag className="" imgObject={content.imageLeft} />
          </div>
          <div className="grid-unit">
            <ModImgTag className="" imgObject={content.imageRight} />
          </div>
        </div>
        <div className="team__accordion">{people}</div>
      </>
    )
  }
}

export default injectIntl(ModBlockTeam)
