import * as React from 'react'

export interface BaseIconProps {
  readonly type?: any
  readonly element: React.ElementType<React.SVGProps<SVGSVGElement>>
  readonly block?: boolean
}

export interface IconProps<P = undefined> extends BaseIconProps {
  readonly styleProps?: P
}

export interface IconPropsWithoutStyleProps extends BaseIconProps {
  // readonly style?: CSSRuleWithTheme | CSSRuleWithTheme[]
}

export interface IconPropsWithStyleProps<P = undefined> extends BaseIconProps {
  readonly styleProps: P
}

export function Icon(props: IconPropsWithoutStyleProps): JSX.Element
export function Icon<P = undefined>(props: IconPropsWithStyleProps<P>): JSX.Element
export function Icon<P = undefined>({ element: Element, block }: IconProps<P>): JSX.Element {
  return <Element className="icon" role="img" />
}
