import * as React from 'react'
import ModImgTag from '../common/ModImgTag'
import { BlockHomeGrid, Project } from '../../interfaces/Interfaces'
import RootState from 'vo/RootState'
import NavLink from 'components/common/NavLink'
import { reduceArrayIntoThree } from '../../common/Utils'
import VisibilitySensor from 'react-visibility-sensor'
import ModRichText from 'components/common/ModRichText'

export interface ModBlockHomeGridProps {
  rootState: RootState
  content: BlockHomeGrid
  currentLanguage: string
}

export interface ModBlockHomeGridState {
  projects: Project[]
}

export default class ModBlockHomeGrid extends React.Component<ModBlockHomeGridProps, ModBlockHomeGridState> {
  constructor(props) {
    super(props)

    this.state = {
      projects: []
    }
  }

  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
        ;[a[i], a[j]] = [a[j], a[i]]
    }
    return a
  }

  getPrioItems(content, count) {
    const items = content.filter(item => {
      if (item.prio) {
        return item
      }
    })

    this.shuffle(items)
    return items.slice(0, count)
  }

  shuffleProjects(content: BlockHomeGrid) {
    const projects = []

    let runningCount = 5
    let competitionsCount = 6
    let realizedCount = 8
    let planningCount = 2

    projects.push(...this.getPrioItems(content.running, runningCount))
    projects.push(...this.getPrioItems(content.competitions, competitionsCount))
    projects.push(...this.getPrioItems(content.realized, realizedCount))
    projects.push(...this.getPrioItems(content.planning, planningCount))

    this.shuffle(projects)
    return projects
  }

  componentDidMount() {
    this.setState({ projects: this.shuffleProjects(this.props.content) })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.content !== this.props.content) {
      const projects = this.shuffleProjects(this.props.content)
      this.setState({ projects: projects })
    }
  }

  render() {
    const { content, rootState } = this.props
    const { projects } = this.state

    let list = projects.map((item, index) => {
      if (!item._i18nCL || !item.page[0] || !item.page[0].navigationNode || !item.page[0].navigationNode['_i18nCL']) {
        return null
      }
      const i18n = item._i18nCL

      return (
        <VisibilitySensor key={item._id} minTopValue={0} partialVisibility={true}>
          {({ isVisible }) => (
            <div key={item._id} className={isVisible ? 'project__block animate visible' : 'project__block animate'}>
              <NavLink
                key={'news_' + index}
                className={'a-internal'}
                alttext={item.page[0].navigationNode['_i18nCL'].label}
                to={{
                  pathname: item.page[0].navigationNode['_i18nCL'].relativeUrl
                }}
              >
                <div className="img-container">
                  <ModImgTag imgObject={item.teaserImage} />
                </div>
                <h4 className="caption">{i18n.title}</h4>
              </NavLink>
            </div>
          )}
        </VisibilitySensor>
      )
    })

    if (content.news.length >= 3) {
      content.news = content.news.slice(0, 3)
    }

    const newsList = content.news.map((item, index) => {
      const date = new Date(item.date)

      let langcode = 'en-EN'
      switch (this.props.currentLanguage) {
        case 'de':
          langcode = 'de-DE'
          break
        case 'en':
          langcode = 'en-EN'
          break
      }

      const month = date.toLocaleString(langcode, { month: 'long' })
      const publicationDate = date.getDate() + '. ' + month + ' ' + date.getFullYear()

      if (!item._i18nCL) {
        return null
      }
      const i18n = item._i18nCL

      return (
        <VisibilitySensor key={item._id} minTopValue={0} partialVisibility={true}>
          {({ isVisible }) => (
            <div className={isVisible ? 'news__block animate  visible' : 'news__block animate'} key={'news_' + index}>
              <div className="news__content">
                <p className="publication-date">{publicationDate}</p>
                <ModImgTag imgObject={item.image} />
                <h3 className="news-title">{i18n.title}</h3>
                <div className="newstext"><ModRichText richText={i18n.text} /></div>
                <NavLink
                  key={'news_' + index}
                  className={'a-internal'}
                  alttext={rootState.websiteSettings.newsNavigationNode._i18nCL.label}
                  to={{
                    pathname: rootState.websiteSettings.newsNavigationNode._i18nCL.relativeUrl,
                    hash: item.anchor ? '#' + item.anchor.toLowerCase() : null
                  }}
                ></NavLink>
              </div>
            </div>
          )}
        </VisibilitySensor>
      )
    })

    //add news to list
    list.splice(3, 0, newsList[0])
    list.splice(8, 0, newsList[1])
    list.splice(13, 0, newsList[2])


    const array = reduceArrayIntoThree(list)
    const items = array.map((item, index) => {
      return (
        <div className="grid-unit" key={index}>
          {item}
        </div>
      )
    })

    return <div className="home__grid block-grid cols-3 tablet-cols-1 desktop-cols-3">{items}</div>
  }
}
