import * as React from "react";
import Mod404 from "./Mod404";
import ModEditBlock from "./common/ModEditBlock";
import ModTemplateBlockList from "./templates/ModTemplateBlockList";
import RootState, { LoadingState } from "../vo/RootState";
import { offlineImage } from "../common/OfflineMedia";
import ModTemplateProjectPage from "./templates/ModTemplateProjectPage";
import ModTemplateHome from "./templates/ModTemplateHome";

export interface ModTemplateBlockListProps {
  rootState: RootState;
  currentLanguage: string;
}

export default class ModContent extends React.Component<ModTemplateBlockListProps> {
  render() {
    const { rootState, currentLanguage } = this.props;
    const { pages } = rootState;
    const page = pages[rootState.currentPageId];

    let contentHtml = null;
    let editMode = null;

    if (page && page.loadedDateTime) {
      if (rootState.editMode) {
        editMode = (
          <ModEditBlock
            entityObject={page}
            title={page._i18nCL.title}
            rootState={rootState}
          />
        );
      }
      if (page.content.templateBlockList) {
        contentHtml = (
          <ModTemplateBlockList
            templateData={page.content.templateBlockList}
            rootState={rootState}
            currentLanguage={currentLanguage}
          />
        );
      }
      if (page.content.templateProjectPage) {
        contentHtml = (
          <ModTemplateProjectPage
            templateData={page.content.templateProjectPage}
            project={page.content.templateProjectPage.project}
            rootState={rootState}
          />
        );
      }
      if (page.content.templateHome) {
        contentHtml = (
          <ModTemplateHome
            templateData={page.content.templateHome}
            rootState={rootState}
            currentLanguage={currentLanguage}
          />
        );
      }
    }

    if (rootState.loadingState === LoadingState.offline) {
      contentHtml = (
        <div id="content" className="loading-state">
          <img alt="offline icon" src={offlineImage} />
        </div>
      );
    } else if (!contentHtml) {
      if (rootState.loadingState === LoadingState.loading) {
        contentHtml = (
          <div id="content" className="loading-state">
            <img
              alt="loading indicator"
              src={require("static/img/loading.gif")}
            />
          </div>
        );
      } else {
        contentHtml = <Mod404 rootState={rootState} />;
      }
    }

    return (
      <div id="content">
        {editMode}
        {contentHtml}
      </div>
    );
  }
}
