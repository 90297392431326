import * as React from 'react'
import ScriptCache from './maps/ScriptCache.js'
import { GoogleMapContext } from './GoogleMapContext'

interface ModMapProps {
  id: string
  options: any
}

const PINS = require('static/img/marker.png')

export default class ModMap extends React.Component<ModMapProps, {}> {
  static contextType = GoogleMapContext

  private scriptCache

  constructor(props) {
    super(props)

    this.scriptCache = null

    this.onScriptLoad = this.onScriptLoad.bind(this)
  }

  onScriptLoad() {
    const map = new window.google.maps.Map(document.getElementById(this.props.id), this.props.options)

    let icon = {
      url: PINS,
      scaledSize: new google.maps.Size(37, 46)
    }

    let marker = new window.google.maps.Marker({
      position: { lat: this.props.options.center.lat, lng: this.props.options.center.lng },
      map: map,
      icon: icon
    })
  }

  componentDidMount() {
    const scriptName = 'googleMaps_de'
    let script = {}
    script[scriptName] =
      'https://maps.googleapis.com/maps/api/js?key=' + this.context.googleMapKey + '&language=de&libraries=places,language'

    if (typeof window !== 'undefined') {
      this.scriptCache = ScriptCache(window, script)

      this.scriptCache[scriptName].onLoad((err, tag) => {
        this.onScriptLoad()
      })
    }
  }

  render() {
    return <div className="map" id={this.props.id} />
  }
}
